export const projects = [
  
  // {
  //   title: "1999 Chateau Ste. Michelle Website",
  //   subtitle: "JavaScript and webpack",
  //   description:
  //     "A faithful recreation of www.ste-michelle.com as it appeared in 1999.",
  //   image: "./1999-winery.png",
  //   link: "https://mitchellneale.github.io/1999-Chateau-Ste-Michelle-Website/",
  // }, 
  // {
  //   title: "Etch A Sketch",
  //   subtitle: "JavaScript DOM Methods",
  //   description:
  //     "A web browser version of the handheld Etch A Sketch game. Includes a reset button and a customizable grid!",
  //   image: "./etch-a-sketch.png",
  //   link: "https://mitchellneale.github.io/etch-a-sketch/",
  // }, 
];

export const testimonials = [
  {
    quote:
      "Placeholder",
    image: "",
    name: "",
    company: "",
  },
  {
    quote:
      "Placeholder",
    image: "",
    name: "",
    company: "",
  }, 
];

export const skills = [
  "Project Management",
  "HTML",
  "MongoDB",
  "CSS",
  "ExpressJS",
  "JavaScript",
  "React",
  "TypeScript",
  "NodeJS",
  "Python",
  "Dynamics 365",
  "Sass",
  "Git",
  "GitHub",
  "C#",
  "Azure",
  "ASP.NET MVC",
  ".NET Core",
  ".NET Framework",
  "SQL Server 2019",
  "Visual Studio 2019",
  "Visual Studio Code",
  "NextJS",
  "VueJS",
];
